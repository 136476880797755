export const INFORMATION = 'information';
export const FILINGS = 'filings';
export const CHARTS = 'charts';
export const NEWS = 'news';
export const ALTERNATIVE = 'alternative-data';
export const SENTIMENT = 'sentiment-analysis';
export const INVESTOR_RELATIONS = 'investor-relations';
export const INSIDER_TRANSACTIONS = 'insider-transactions';
export const DCSC_SECTORS = 'dcsc-sectors';
export const SMART_WATCHLIST = 'smart-watchlist';
export const ESG = 'esg';
export const CAPITAL_STRUCTURE = 'capital-structure';
export const INVESTMENTS = 'investments';
